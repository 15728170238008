html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wrap {
  position: relative;

}

img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

.entry-content img {
  width: auto;
  max-width: 100%;
  height: auto;
}

.main.no-pad {
  padding: 0;
}

.no-pad {
  padding: 0;
}

.object-fit { // special rule for our object-fit polyfill.\
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.admin-bar nav#navigation {
  @include screen-xs() {
    margin-top: 30px;
  }
}

a {
  color: $minday-red;
  &:visited {
    color: $minday-red;
  }
  &:hover,&:active,&:focus {
    color: darken($minday-red, 20%);
  }
}

.img-border {
  border: 1px solid $minday-gray;
}

.red-type {
  color: $minday-red;
}

.blue-type {
  color: $minday-blue;
}

h1 {
  font-size: 25px;
  @include screen-md() {
    font-size: 50px;
  }
}

h2 {
  font-size: 25px;
  @include screen-md() {
    font-size: 32px;
  }
}

.h1, .h2, .h3, h1, h2, h3 {
    margin-top: 9px;
    margin-bottom: 5px;
    @include screen-md() {
      margin-top: 18px;
      margin-bottom: 9px;
    }
}

// responsive video container
// wrap embedded video in a div with .video-container to make responsive - it fills the container it is in.
.video-container {
  height: 0;
  //margin-top: 15px;
  overflow: hidden;
  position: relative;
  padding-bottom: 56.25%; // padding keeps the video height in ratio
  //padding-top: 30px;
  max-width: 1500px;
  margin: 0 auto;
}
.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

}

figure.wp-caption {
  max-width: 100%;
}