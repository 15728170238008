@mixin screen-xs() { // larger than 420px
  @media(min-width: $screen-xs-min) {
    @content;
  }
}

@mixin screen-sm() { // larger than 768px
  @media(min-width: $screen-sm-min) {
    @content;
  }
}

@mixin screen-md() { // larger than 992px
  @media(min-width: $screen-md-min) {
    @content;
  }
}

@mixin screen-lg() { // larger than 1200px
  @media(min-width: $screen-lg-min) {
    @content;
  }
}

@mixin xs-only() { // smaller than 480px
  @media(max-width: $screen-xs) {
    @content;
  }
}

@mixin ie-fix-media-query() {
  @media all and (min-width: 1px) and (max-width: $screen-sm - 1) {
    @content;
  }
}