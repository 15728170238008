.about {

  .main {
    padding: 0 18px;
    @include screen-sm() {
      padding: 0 27px;
    }
  }

  .row .col-sm-6:nth-child(even).about-image {
    margin-top: 54px;
    @include screen-sm() {
      margin-top: 0;
    }
  }

  h2 {
    font-size: 25px;
    @include screen-sm() {
      margin: 0;
      padding: 0;
    }
    @include screen-md() {
      font-size: 50px;
    }
  }

  .lecture-container {
    width: 100%;
    margin: 54px 0 153px 0;

    h2 {
      margin-bottom: 54px;
    }
    .lecture-text {
      font-size: 18px;
      color: $minday-gray;
      line-height: 1.2;
      @include screen-sm() {
        padding-left: 20px;
        font-size: 20px;
      }
    }
  }

  .awards-section {
    .awards {
      width: 100%;
      @include screen-sm() {
        columns: 400px 2;
        column-gap: 30px;
      }
    }
    .all-recognition-button-container {
      width: 100%;
      margin: 45px 0;
      text-align: center;
      .btn {
        margin: 0 auto;
      }
    }
    .award-text {
      a {
        color: $minday-gray-dark;
        text-decoration: underline;
      }
    }
  }


  .allied-practices {
    margin-top: 45px;
  }

  .allied-practices-image-container {
    position: relative;
    .rollover {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
      &:hover {
        opacity: 1;
      }
    }
  }

  .subtitle {
    margin: 36px 0 18px 0;
    font-size: 16px;
    // font-weight: 700;
  }
  .current-staff h2 {
    margin-bottom: 50px;
  }
  .current-staff-container {
    @include screen-sm() {
      columns: 2;
      column-gap: 50px;
      //display: flex;
      //flex-wrap: wrap;
      //flex-direction: column;
    }
    width: 100%;
    .staff-member {
      break-inside: avoid;
      width: 100%;
      margin-bottom: 50px;

      h3 {
        color: $minday-blue;
        font-size: 17px;
        margin: 0 0 15px 0;
      }
      p {
        font-size: 14px;
      }
    }
  }

  .partners, .studio, .awards-container, .current-staff-container {
    margin: 54px 0 99px;
  }



}
