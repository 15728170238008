.blog {
  .page-section-header {
    margin-bottom: 45px;
  }
  .news-item-container {
    width: 100%;
    @include screen-sm() {
      //display: flex;
    }
    .news-item {
      @include screen-sm() {
        display: flex;
      }
      .news-item-featured-image {
        margin-bottom: 27px;
        @include screen-sm() {
          flex: 0 0 50%;
          margin-bottom: 45px;
          max-width: 50%;
          padding-right: 15px;
        }
      }
      .news-item-content {
        margin-bottom: 90px;
        font-size: 15px;
        @include screen-sm() {
        font-size: 18px;
          flex: 0 0 50%;
          padding-left: 15px;
          margin-bottom: 45px;
          max-width: 50%;
        }
        h2 {
          margin: -9px 0 9px 0;
          a {
            color: #000;
            display: inline-block;
            font-size: 20px;
            font-weight: 700;
            @include screen-sm() {
              font-size: 26px;
            }
          }
        }
      }
    }
  }
}

.single-post {
  article {
    margin-bottom: 100px;
    width: 100%;
    max-width: 100%;
  }
  .project-image {
    margin-top: 18px;
    @include screen-sm() {
      margin-top: 54px;
    }
  }
  .news-post-container {
    margin-top: 45px;
    width: 100%;
    max-width: 100%;
    @include screen-sm() {
      display: flex;
    }

    .post-meta {
      @include screen-sm() {
        flex: 0 0 calc(30% - 15px);
        max-width: calc(30% - 15px);
        margin-right: 15px;
      }
      ul.news-meta-list {
        margin: 0;
        padding: 0;
        li {
          font-size: 14px;
          list-style: none;
          border-top: 1px solid #b3b3b3;
          padding: 15px 5px;
          span {
            display: block;
            font-size: 14px;
          }
          .heading {
            font-weight: bold;
          }
        }
      }
    }
    .post-content {
      font-size: 18px;
      @include screen-sm() {
        flex: 0 0 calc(70% - 15px);
        max-width: calc(70% - 15px);
        margin-left: 15px;
      }
      h1.entry-title {
        font-size: 38px;
        margin-top: 27px;
        @include screen-sm() {
          margin: -7px 0 18px 0;
        }
      }
      img {
        margin: 18px auto 9px;
        @include screen-sm() {
          width: auto;
          &.alignright {
            margin-left: 18px;
          }
          &.alignleft {
            margin-right: 18px;
          }
        }
      }
      div.wp-caption {
        border: 0;
        padding: 0;
        @include screen-sm() {
          width: auto;
          &.alignright {
            margin-left: 18px;
          }
          &.alignleft {
            margin-right: 18px;
          }
        }
        .wp-caption-text {
          color: $minday-gray-dark;
          display: inline-block;
          font-size: 15px;
          font-weight: 700;
          line-height: 1.4;
          padding-left: 9px;
          padding-right: 9px;
          text-align: center;
          width: 100%;
          @include screen-md() {
            padding-left: 27px;
            padding-right: 27px;
          }
          @include screen-sm() {
            font-size: 18px;
          }
        }
      }
      .news-shares {
        margin: 18px auto 0;
        text-align: center;
        clear: both;
        @include screen-sm() {
          margin-top: 36px;
        }
        ul.socials {
          height: 25px;
          margin: 0 auto;
          padding: 0;
          list-style: none;
          display: flex;
          display: -webkit-flex;
          justify-content: center;
          -webkit-justify-content: center;
          @include screen-sm() {
            justify-content: flex-start;
            -webkit-justify-content: flex-start;
          }
          li {
            float: left;
            margin: 0 2px;
            border-radius: 3px;
            width: 30px;
            height: 25px;
            text-align: center;
            a {
              display: block;
              height: 100%;

            }
            &.email-share {
              background-color: #0589ff;
            }
            &.facebook-share {
              background-color: #316499;
            }
            &.twitter-share {
              background-color: #22c5f0;
            }
            &.google-plus-share {
              background-color: #e93f2e;
            }
            img {
              display: inline-block;
              height: 12px;
              margin: 0 auto;
              width: auto;
              vertical-align: 1px;
            }
          }
        }
      }
    }
  }
  .news-nav {
    text-align: right;
    a {
      font-size: 18px;
      margin: 0 10px 36px;
    }

  }
}