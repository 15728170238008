.post-type-archive-minday_project, .page-template-template-all-projects-page  {
  .project-tiles-container {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-top: 54px;
    @include screen-sm() {
      margin-left: -5px;
      margin-right: -5px;
    }
    &.result {
      margin-top: 0;
    }
    .project-tile-container {
      position: relative;
      &:hover .tile-content{
        @include screen-sm() {
          margin-top: -36px;
          transition: margin-top 0.2s ease-out;
        }
      }
      a {
        display: block;
      }

      .tile-content {
        background-color: white;
        float: left;
        padding: 9px 0 18px;
        position: relative;
        width: 100%;
        z-index: 1;
        @include screen-sm() {
          margin-top: 0px;
          transition: margin-top 0.2s ease-in;
        }
        .all-projects-tile-title {
          margin-bottom: 0;
          a {
            color: black;
            font-size: 18px;
            font-weight: bold;
          }
        }
        ul.all-project-category-list {
          color: $minday-gray;
          display: block;
          float: left;
          list-style: none;
          margin: 0;
          padding: 0;
          li {
            display: inline;
            float: left;
            span {
              float: left;
              margin: 0 10px;
            }
            a {
              font-size: 14px;
              color: $minday-gray;
            }
          }

        }
      }
      @include screen-sm() {
        //width: calc(25% - 10px);
        width: calc(50% - 10px);
        flex-basis: calc(50% - 10px);
        flex-shrink: 1;
        flex-grow: 1;
        margin: 5px;
        max-width: calc(50% - 10px);
        &:nth-child(odd) {
          margin-right: 5px;
        }
        &:nth-child(even) {
          margin-left: 5px;
        }
      }
      @include screen-md() {
        width: calc(33% - 10px);
        flex-basis: calc(33% - 10px);
        max-width: calc(33% - 10px);
      }
      @include screen-lg() {
        width: calc(25% - 10px);
        max-width: calc(25% - 10px);
        flex-basis: calc(25% - 10px);
      }

      .project-tile-title {
        font-size: 18px;
        line-height: 1.2;
      }
    }

  }

}