.single-minday_project {

  #category-page-list {
    margin-left: 0;
  }

  #project-category-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 27px 0 10px 0;
    li {
      margin: 10px;
    }
    &:first-child {
      padding-left: 5px;
      @include screen-sm() {
        padding-left: 16px;
      }
    }
  }

  article {
    float: left;
    width: 100%;
  }

  .title {
    position: relative;
    .reveal-title {
      position: absolute;
      top: 0;
      left: 0;
      height: 105%;
      width: 100%;
      background-color: white;
      z-index: 2;
      transform-origin: right;
    }
    h1 {
      font-size: 36px;
      font-weight: 700;
      margin-top: 0;
      margin-right: 0;
      margin-bottom: 40px;
      margin-left: 13px;  // left margin is 22px because circular font puts a 7px space in headings
      @include screen-sm() {
        font-size: 52px;
        margin-left: 22px;
        padding: 0;
      }
      @include screen-md() {
        font-size: 72px;
      }
      @include screen-lg() {
        font-size: 94px;
      }
    }
  }

  header {
    display: block;
    float: left;
    width: 100%;
    ul.project-categories {
      margin: 0;
      padding: 0;
      li {
        margin: 0 10px;
        list-style-type: none;
        display: inline;
        &:first-of-type {
          margin: 0 10px 0 0;
        }
      }
    }
    .intro {
      font-size: 18px;
      color: $minday-blue;
      margin: 45px auto 0;
      @include screen-sm() {
        font-size: 22px;
      }
      @include screen-md() {
        font-size: 26px;
      }
    }
    .project-header-content {
      margin-top: 45px;
      float: left;
      width: 100%;
    }
    .project-details-container {
      width: 100%;
      float: left;
      @include screen-sm() {
        padding-right: 20px;
        width: calc(50% - 30px);
      }
      ul.project-details {
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          border-top: 1px solid #b3b3b3;
          padding: 15px 5px;
          span {
            display: block;
            &.heading {
              font-weight: bold;
            }
          }
        }
      }
    }
    .project-description-container {
      float: left;
      width: 100%;
      @include screen-sm() {
        width: calc(50% + 30px);
        padding-left: 20px;
      }
      .project-description {
        padding-bottom: 13px;
      }

      p {
        color: $minday-gray-dark;
        font-size: 14px;
        line-height: 1.4;
        @include screen-sm() {
          font-size: 16px;
        }
      }
      .project-shares {
        //margin: 0 auto;
        //text-align: center;
        ul.socials {
          height: 25px;
          margin: 0 auto;
          padding: 0;
          list-style: none;
          display: flex;
          display: -webkit-flex;
          justify-content: left;
          -webkit-justify-content: left;
          @include screen-sm() {
            justify-content: flex-start;
            -webkit-justify-content: flex-start;
          }
          li {
            float: left;
            margin: 0 2px;
            border-radius: 3px;
            width: 30px;
            height: 25px;
            text-align: center;
            a {
              display: block;
              height: 100%;

            }
            &.email-share {
              background-color: #0589ff;
            }
            &.facebook-share {
              background-color: #316499;
            }
            &.twitter-share {
              background-color: #22c5f0;
            }
            &.google-plus-share {
              background-color: #e93f2e;
            }
            img {
              display: inline-block;
              height: 12px;
              margin: 0 auto;
              width: auto;
            }
          }
        }
      }
    }
  }

  .entry-content {
    float: left;
    width: 100%;
  }
  .caption {
    color: $minday-gray-dark;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4;
    text-align: center;
    width: 100%;
    padding-top: 36px;
    padding-left: 9px;
    padding-right: 9px;
    @include screen-md() {
      padding-left: 54px;
      padding-right: 54px;
    }

    @include screen-sm() {
      font-size: 16px;
    }

  }

  .content-container {
    width: 100%;
    margin-top: 150px;

    @include screen-md() {
      margin-top: 300px;
    }

    .container {
      padding-left: 0;
      padding-right: 0;
       @include screen-sm() {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .image-text {
      text-align: center;
      img {
        max-height: 100vh;
        width: auto;
        margin: 0 auto;
      }
      .caption {
        // margin-top: 27px;
        // padding: 0;
      }
    }

    .image-full-width {
      .caption {
        // padding-left: 9px;
        // padding-right: 9px;
        // @include screen-md() {
        //   padding-left: 54px;
        //   padding-right: 54px;
        // }
      }
    }

    // flexible content type
    .added-margin {
      margin-bottom: 180px !important;
      @include screen-sm() {
        margin-bottom: 0px !important;
      }
    }
    .image-inset {
      text-align: center;
      .flex-container {
        width: 100%;
        @include screen-sm() {
          display: flex;
          flex-direction: row;
        }
        .main-image {
          margin-bottom: 40px;
          @include screen-sm() {
            margin-bottom: 0;
            width: 50%;
          }
          img {
            width: auto;
            height: auto;
            max-height: 100vh;
          }
        }
        .inset-image {
          @include screen-sm() {
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          img {
            margin: 0 auto;
            max-width: 70%;
            height: auto;
            max-height: 70vh;
          }
        }
      }
      .text {
        // margin-top: 36px;
        // width: 100%;
      }
    }
    .flipbook-animation {
      will-change: translate;
      transform: translate3d(0,0,0);
      -webkit-transform: translate3d(0,0,0);
      text-align: center;
      position: relative;
      width: 100%;
      height: 100vh;

      .animation-container {
        .animation-sequence {
          position: relative;
          width: 100%;
          height: 100vh;
          .animated-image {
            height: inherit;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
           // border: 1px solid red;
            &.first-image {

            }
            img {
              top: 50%;
              left: 50%;
              transform: translate(-50%,-50%);
              position: absolute;
              height: auto;
              max-height: 90vh;
              max-width: 90vw;
              width: auto;

              @include screen-sm() {
                max-width: 80vw;
                max-height: 80vh;
              }
              @include screen-md() {
                max-width: 60vw;
                max-height: 60vh;
              }
            }
          }
        }
        .text {
          position: absolute;
          bottom: 0;
          height: 50px;
          text-align: center;
          width: 100%;
          .caption {
            padding-top: 0;
          }
        }
      }
    }

    &:first-child.content-container {
      margin-top: 75px;

      @include screen-md() {
        margin-top: 150px;
      }
    }

    .image-full-width {
      img {
        width: 100%;
        max-width: 100%;
        height: auto;
      }

      .text {
        margin-top: 40px;
        width: 100%;
      }

    }
    .image-pullquote {
      @include screen-sm() {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
      .quote {
        padding: 40px 20px;
        @include screen-sm() {
          padding: 100px;
        }
        color: $minday-gray-medium;
        @include screen-sm() {
          width: 50%;
          flex-basis: 50%;
          flex-shrink: 0;
          flex-grow: 0;
        }
        &.pullquote {
          font-size: 20px;
          @include screen-sm() {
            font-size: 26px;
          }
        }
        &.plain_text {
          font-size: 14px;
          @include screen-sm() {
            font-size: 16px;
          }
        }
      }

      .image {
        @include screen-sm() {
          width: 50%;
          flex-basis: 50%;
          flex-shrink: 0;
          flex-grow: 0;
          text-align: center;
        }
        img {
          max-height: 100vh;
          width: auto;
          margin: 0 auto;
        }
      }
    }

    .project_subtitle {
      color: black;
      font-size: 19px;
      font-weight: 700;
      margin: 0 auto;
      padding: 0 15px;
      @include screen-sm() {
        margin-bottom: 9px;
        padding: 0 25vw;
      }
    }

    .project_text_block {
      color: $minday-gray-dark;
      font-size: 14px;
      padding: 0 15px;
      @include screen-sm() {
        font-size: 16px;
        padding: 0 25vw;
        margin: 0 auto;
      }
    }

  }
  .recognition {
    margin-top: 108px;
    h3 {
      color: $minday-gray-dark;
      font-size: 16px;
      font-weight: 700;
      padding-bottom: 7px;
    }
    .publications h3 {
      color: $minday-gray-dark;
      margin-top: 72px;
      @include screen-md() {
        margin-top: 18px;
      }
    }
    .awards {
      padding-right: 0;
      @include screen-md() {
        padding-right: 54px;
      }
      ul {
        padding: 0 0 0 15px;
        margin: 0;
        li {
          line-height: 1.4;
          @include screen-sm() {
            font-size: 16px;
          }
        }
      }
    }
  }

  footer.project-navigation {
    display: block;
    background-color: black;
    float: left;
    width: 100%;
    position: relative;
    z-index: 1;
    a {
      color: $minday-gray-light;
      font-size: 18px;
      font-weight: bold;
      &:hover {
        color: $minday-gray-light;
        text-decoration: underline;
      }
    }
    .project-tiles-container {
      width: 100%;
      @media(min-width: 480px) {
        display: flex;
        -webkit-flex-wrap: wrap;
        display: -webkit-flex;
        flex-wrap: wrap;
        flex-direction: row;
        position: relative;
      }
      .project-tile-container {
        @media(min-width: 480px) {
          /* flex: 0 0 calc(50% - 20px); doesn't work in IE */
          flex-grow: 0;
          flex-shrink: 0;
          flex-basis: auto;
          width: calc(50% - 5px);
        }
        @include screen-sm() {
          width: calc(50% - 9px);
        }
        @include screen-md() {
          width: calc(50% - 18px);
        }
        &.previous {
          @media(min-width: 480px) {
            margin-right: 5px;
          }
          @include screen-sm() {
            margin-right: 9px;
          }
          @include screen-md() {
            margin-right: 18px;
          }
        }
        &.next {
          @media(min-width: 480px) {
            margin-left: 5px;
          }
          @include screen-sm() {
            margin-left: 9px;
          }
          @include screen-md() {
            margin-left: 18px;
          }
          &.next-single {
            @media(min-width: 480px) {
              margin-left: calc(50% + 5px);
            }
            @include screen-sm() {
              margin-left: calc(50% + 9px);

            }
            @include screen-md() {
              margin-left: calc(50% + 18px);
            }

          }
        }
        .project-tile-content {
          padding: 0 10px;
          @include screen-sm() {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.8);
            opacity: 1;
            transition: opacity 0.2s ease-in-out;
          }
          .project-tile-title {
            color: $minday-gray-light;
            @include screen-sm() {
              color: white;
            }
          }
          &:hover {
            @include screen-sm() {
              opacity: 0;
            }
          }
          &:hover .project-tile-title {
            text-decoration: underline;
            // animation
            opacity: 1;
            transform: translateY(0px);
            transition: 0.2s;
          }
          @include screen-sm() {
            &:hover .project-tile-title {
              text-decoration: none;
            }
          }
          .project-tile-title {
            //animation
            opacity: 1;
            transform: translateY(0px);
            transition: 0.3s;
          }

        }
      }
    }

  }
}

article {
  position: relative;
  float: left;
}

.photo-credit {
  color: $minday-gray-dark;
  font-size: 13px;
  padding-top: 90px;
  @include screen-sm() {
    font-size: 13px;
  }
  @include screen-md() {
    font-size: 14px;
  }
  .photographer-name {
    padding-left: 5px;
    vertical-align: 1px;
  }
}
