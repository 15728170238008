// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$minday-blue :          rgba(30,129,186,1);
$minday-orange :        rgba(222,77,38,1);
$minday-red :           rgba(222,77,38,1);
$minday-gray-light :    rgba(237,237,230,1);
$minday-gray :					rgba(170,170,165,1);
$minday-gray-medium :		rgba(101,101,94,1);
$minday-gray-dark :     rgba(94,91,86,1);
$minday-gray-darker :   rgba(51,51,47,1);
$minday-black :					rgba(0,0,0,1);

$brand-primary :        $minday-blue;
$text-color:            $minday-gray-dark;
$headings-color:        $minday-black;

$font-family-sans-serif : $minday_font;
$headings-font-family : $minday_font;
