.page-template-template-curated_category-php {

  .main {
    padding: 0 18px;
    @include screen-sm() {
      padding: 0 27px;
    }
  }

  .project-tiles-container {
  	margin-top: 54px;
  }


}