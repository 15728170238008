#scrollToTop {
  bottom: 36px;
  color: $minday-gray-light;
  cursor: pointer;
  font-size: 11px;
  font-weight: 700;
  left: auto;
  opacity: 0;
  padding:10px;
  position: fixed;
  right: 27px;
  text-align: center;
  transition: opacity .2s ease-in-out;
  top: auto;
  z-index: 5;
  width: 60px;
  height: 60px;
  &.show {
    opacity: 1;
  }
  #up-arrow {
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    height: 100%;
    transition: fill .2s ease-in-out;
    .circle-outline {
      fill: rgba(255,255,255,0.3);
      // stroke: rgba(255,255,255,0.3);
      // stroke-miterlimit: 10;
      transition: fill 0.2s ease-in-out;
      &:hover {
        fill: rgba(255,255,255,0.8);
        // stroke: rgba(255,255,255,1);
        transition: fill 0.2s ease-in-out;
      }
    }
    g.arrow polygon {
      pointer-events: none;
      fill: #000;
    }
  }
}

a.btn-triangle {
  .st0{
    fill: $minday-gray-light;
    transition: fill .2s ease-in-out;
  }
  .st0:hover {
    fill: $minday-gray-dark;
    transition: fill .2s ease-in-out;
    & + .st2 text {
      fill: $minday-gray-light;
      transition: fill .2s ease-in-out;
      text-decoration: none;
    }
  }
  .st2 text {
    fill: $minday-gray-dark;
    font-size: 14px;
    font-weight: 400;
    transition: fill .2s ease-in-out;
    text-decoration: none;
    pointer-events: none;
    cursor: default;
  }
}

#category-page-list {
  display: flex;
  flex-wrap: wrap;
  align-items: left;
  justify-content: left;
  list-style: none;
  margin: 99px 0 0;
  padding: 0;
  width: 100%;
  @include screen-md() {
    width: 670px;
  }
  li {
    margin: 0;
    padding: 0;
  }
  li>a {
    display:inline-block;
  }
}

.btn-triangle svg {
  height: auto;
  width: 145px;
  .st2 text {
    font-size: 20px;
  }
  @include screen-md() {
    height: auto;
    width: initial;
    .st2 text {
      font-size: initial;
    }
  }
}

a.btn-more, .btn-more {
  padding: 20px 45px;
  border-radius: 0;
  border: 1px solid #000;
  color: #5e5b56;
  background-color: white;
  position: relative;
  transition: background-color 0.4s ease-in-out 0s;
  &:hover {
    background-color: #ededed;
    transition: background-color 0.4s ease-in-out 0.4s;
  }
}

// load more button animations
$btn-off-color: rgba(0,0,0,0.3);
$btn-over-color: rgba(0,0,0,0.3);
$btn-loading-color: black;

.btn.primary-btn {
  font-weight: bold;
  padding: 20px 45px;
  border-radius: 0;
  color: $minday-red;
  background-color: white;
  position: relative;
  transition: background-color 0.2s ease-in-out 0s;
  &.default {
    font-weight: bold;
    &:after {
      content: "";
      height: 1px;
      display: block;
      background-color: $btn-off-color;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      transition: width 0.2s ease-in-out 0.4s;
    }
    &:hover:after {
      background-color: $btn-over-color;
      width: 100%;
      transition: width 0.2s ease-in-out 0s;
    }
  }
  &:hover {
    color: black;
  }
  &:active {
    background-color: $minday-red;
    box-shadow: none;
    color: white;
  }
  span.animation {
    display: block;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    transition: all 0.2s ease-in-out;
    &:before, &:after {
      content : "";
      display: block;
      background-color: $btn-off-color;
      position: absolute;
    }
    &.first {
      height: 100%;

      &:before, &:after {
        content: "";
        width: 1px;
        height: 100%;
        transform: scale3d(1, 0, 1);
        transition: transform 0.2s ease-in-out 0.2s, background 0.2s ease-in-out 0s;
        transform-origin: bottom center;
      }
      &:before {
        left: 0;
      }
      &:after {
        right: 0;
      }
    }
    &.second {
      height: 1px;
      width: 100%;
      &:before, &:after {
        content: "";
        height: 1px;
        bottom: 0;
        width: 100%;
        transform: scale3d(0, 1, 1);
        transition: transform 0.2s ease-in-out 0s, background 0.2s ease-in-out ;
      }
      &:before {
        left: 0;
        transform-origin: bottom left;
      }
      &:after {
        right: 0;
        transform-origin: bottom right;
      }
    }
  }
  &:hover span.first.animation:before, &:hover span.first.animation:after {
    transform: scale3d(1, 1, 1);
    transition: transform 0.2s ease-in-out 0.2s, background 0.2s ease-in-out 0s;
  }
  &:hover span.second.animation:before, &:hover span.second.animation:after {
    transform: scale3d(1, 1, 1);
    transition: transform 0.2s ease-in-out 0.4s, background 0.2s ease-in-out 0.4s;
  }
  &:hover span {
    &.animation {
      &:before, &:after {
        background-color: $btn-over-color;
      }
      &:before {
        left: 0;
      }
      &:after {
        right: 0;
      }
    }

  }
  &.loading {
    border: 1px solid $btn-loading-color;
  }

}


.glyphicon.spinner {
  animation: spin 1s infinite linear;
  -webkit-animation: spin2 1s infinite linear;
  vertical-align: -1px;
  margin-left: 5px;
}
@keyframes spin {
  from { transform: scale(1) rotate(0deg);}
  to { transform: scale(1) rotate(360deg);}
}

@-webkit-keyframes spin2 {
  from { -webkit-transform: rotate(0deg);}
  to { -webkit-transform: rotate(360deg);}
}

.more-posts {
  margin: 45px 0;
  text-align: center;
  .more-posts-button {
    margin: 0 auto;

  }
}

.button-container {
  // margin: 100px auto 0;
  margin: 0 auto;
  text-align: center;
}
