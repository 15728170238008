.home {

  #mediaContainer {

    .itemContainer {
      position: fixed;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: -1;
      opacity: 0;

      .mediaContentContainer {
        width: 100vw;
        height: 100vh;
      }

    }
  }

  #pageContentContainer {
    color: white;

    .storyContentItem {
      width: 100vw;
      height: 100vh;

      .storyContent:hover,
      .storyContent:focus {
        & .slider-subtitle .glyphicon {
          left:10px;
          transition: left 0.2s;
        }
      }
    }
    .slider-title {
      font-size: 45px;
      font-weight: 700;
      padding: 0;
      margin: 0;

      @include screen-xs() {
        font-size: 60px;
      }

      @include screen-sm() {
        font-size: 72px;
      }

      @include screen-md() {
        font-size: 108px;
      }

      a {
        color: white;
        text-shadow: 0 0 9px rgba(0,0,0,0.25);
        text-decoration: none;

        &:hover, &:focus {
          cursor: pointer;
          cursor: hand;
        }
      }

    }

    .slider-subtitle {
      color: #ffffff;
      font-size: 14px;
      // text-shadow: 0 0 1px rgba(0,0,0,1);

      @include screen-md() {
        font-size: 18px;
        margin-top: 9px;
      }

      &:hover, &:focus {
        cursor: pointer;
        cursor: hand;
        text-decoration: none;

        .glyphicon {
          left:10px;
          transition: left 0.2s;
        }
      }

      .glyphicon {
        left: 0;
        top: 2px;
        transition: left 0.2s;
      }
    }

    .photo .storyContent {
      margin: 27px;
    }

    .news {

      img {
        border: none;
        margin-bottom: 25px;
        width: 100%;
        max-width: 600px;
        height: auto;
      }

      .news-title {
        color: inherit;
        display: block;
        font-size: 15px;
        font-weight: 700;
        margin-bottom: 10px;
        text-align: left;
        text-decoration: none;

        @include screen-md() {
          font-size: 18px;
        }
         @include screen-lg() {
          font-size: 21px;
        }

        &:hover {
          cursor: pointer;
          cursor: hand;
          text-decoration: underline;

          .glyphicon {
            left:10px;
            transition: left 0.2s;
          }

        }

        .glyphicon {
          left: 0;
          top: 2px;
          transition: left 0.2s;
        }

      }

      .news-text {
        p {
          font-size: 13px;

          @include screen-md() {
            font-size: 16px;
          }
        }
      }

      .storyContent {
        text-align: center;
        width: 90%;
        margin: 0 auto;
        max-width: 600px;
        p {
          text-align: left;
        }

      }

    }

    .hidden-xs-down {
      border: 1px solid red;
    }

  }

  @keyframes moveArrow {
    0%, 100% {transform: translateY(0)}
    50% {transform: translateY(-10px)}
  }

  .downScroll {
    bottom: 0;
    display: inline-block;
    padding: 27px;
    pointer-events: none;
    position: fixed;
    right: 18px;
    text-align: right;
    z-index: 3;

    svg#upScrollButton {
      margin-right: 9px;
      transform: rotate(180deg);
      transition: all 0.2s ease-in-out;
      visibility: hidden;
    }

    svg#upScrollButton,
    svg#downScrollButton {
      pointer-events: auto;
      height: 50px;
      transition: opacity 0.2s ease-in-out;
      width: 50px;
      @include screen-sm() {
        height: 75px;
        width: 75px;
      }

      &:hover {
        cursor: pointer;
        cursor: hand;
      }

      .scroll-circle {
        fill: rgba(255,255,255,0.3);
        // stroke: rgba(255,255,255,1);
        stroke-miterlimit: 10;
        // opacity: 0.3;
        transition: fill 0.2s ease-in-out;
      }

      &:hover .scroll-circle {
        fill: rgba(255,255,255,0.8);
        // opacity: 1;
        transition: fill 0.2s ease-in-out;
      }

    }

  }

  .arrow-animation {
    animation: moveArrow 1.5s infinite;
  }

}