footer.main-footer {
  width: 100%;
  float: left;
}
#footer {
  background-color: black;
  height: auto;
  margin-top: 90px;
  padding: 45px 18px 18px;
  width: 100%;

  @include screen-xs() {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    // align-items: space-between;
    height: auto;
  }

  .logo {
    margin: 0;
    padding: 0;
    a {
      display: block;
      position:relative
    }
    svg {
      display: block;
      height: 50px;
      width: 50px;
    }
    @include screen-xs() {
      flex: 2 0 0;
    }
    @include screen-md() {
      svg {
        height: 70px;
        width: 70px;
      }
    }
  }
  .sobend {
    color: white;
    font-size: 90%;
    margin-bottom: 5px;
    text-align: left;
    align-self: flex-end;
    a {
      border: 1px solid white;
      color: white;
      padding: 1px 4px 3px;
      margin: 0 5px;
      &:hover {
        border-color: $minday-orange;
        color: $minday-orange;
        text-decoration: none;
      }
    }
  }
  .address {
    color: $minday-gray-light;
    font-size: 13px;
    line-height: 1.3;
    list-style-type: none;
    margin-top: 18px;
    @include screen-xs() {
      display: inline-block;
      margin: 0 18px;
    }
    @include screen-md() {
      font-size: 15px;
      line-height: 1.4;
    }

  }
  .social-list {
    // float: left;
    margin-top: 27px;
    align-items: left;
    justify-content: left;
    padding-left: 0;
    @include screen-sm() {
    margin-top: 18px;
      padding-left: 40px;
    }
    svg {
      fill: white;
    }
    @include screen-xs() {
      margin-top: 0;
    }
  }
}

.single-minday_project {
  margin-top: 0;
  #footer {
    margin-top: 0;
  }
}