// max width for some pages:
.about .main, .contact .main, .single-post .main, .page.default-page-template .main {
  max-width: 1500px;
  margin: 0 auto;
}


.page-section-header {
   margin: 18px 0 0;

	@include screen-md() {
    margin: 54px 0 0;
	}

	.page-title {
		margin-top:0;
		padding-top:0;
	}

	.page-intro {
  	margin-top: 27px;
  	@include screen-sm() {
      margin-top: 0;
    }

  	p {
  		color: $minday-gray;
  		font-size: 18px;
  		line-height: 1.2;
  		@include screen-md() {
  			font-size: 20px;
  		}
  	}
  }
}

.post-type-archive-minday_project,
.post-type-archive-minday_recognition,
.page-template-template-all-projects-page,
.blog, .single-post {
  .main {
    padding-right: 18px;
    padding-left: 18px;

    @include screen-sm() {
      padding-right: 27px;
      padding-left: 27px;
    }
  }
  .project-tiles-container, .container-recognition-grid {
    margin-top: 54px;
  }
}

.contact {
  h1 {
    margin-bottom: 20px;
    @include screen-sm() {
      margin-bottom: 0;
    }
  }
  .page-section-header {
    margin-bottom: 45px;
  }

  .contact-socials {
    p {
      margin: 0;
      color: $minday-gray;
    }
    span {
      margin-left: 5px;
    }
    a {
      display: block;
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-word;
      @include screen-sm() {
        display: inline;
      }
    }
  }

  .vcard {
    color: $minday-gray;
    font-size: 18px;
    line-height: 1.2;
    margin: 27px 0 45px;
    @include screen-md() {
      font-size: 20px;
      margin: 27px 0;
    }
    .org {
      color: black;
      font-weight: 400;
      font-size: 20px;
      line-height: 1.6;
      @include screen-sm() {
        font-size: 30px;
      }
    }
  }

  .wrap {
    min-height: calc(100vh - 110px);
  }

  .main {
    padding-right: 18px;
    padding-left: 18px;

    @include screen-sm() {
      padding-right: 27px;
      padding-left: 27px;
    }
  }
}

.page.default-page-template {
  header {
    width: 100%;
    img {
      width: 100%;
      max-width: 100%;
    }
  }
  article {
    width: 100%;
  }
  font-size: 18px;

  .main { // match other similar pages
    padding: 0 18px;
    @include screen-sm() {
      padding: 0 27px;
    }
    .entry-content {
      width: 100%;
      max-width: 900px;
      margin: 45px auto 0;
    }
  }
  h1 {
    font-size: 38px;
    margin-top: 27px;
    @include screen-sm() {
      margin: -7px 0 18px 0;
    }
  }
  img {
    margin: 18px auto 9px;
    @include screen-sm() {
      width: auto;
      &.alignright {
        margin-left: 18px;
      }
      &.alignleft {
        margin-right: 18px;
      }
    }
  }
  div.wp-caption {
    border: 0;
    padding: 0;
    @include screen-sm() {
      width: auto;
      &.alignright {
        margin-left: 18px;
      }
      &.alignleft {
        margin-right: 18px;
      }
    }
    .wp-caption-text {
      color: $minday-gray-dark;
      display: inline-block;
      font-size: 15px;
      font-weight: 700;
      line-height: 1.4;
      padding-left: 9px;
      padding-right: 9px;
      text-align: center;
      @include screen-md() {
        padding-left: 27px;
        padding-right: 27px;
      }
      @include screen-sm() {
        font-size: 18px;
      }
    }
  }
}