.page-template-template-recognition {

	.recognition-section {
		margin-top: 90px;

		#awards {
			margin-top: 27px;
		}

		@include screen-md() {
			margin-top: 144px;

			#awards {
				margin-top: 63px;
			}
		}

	 .slick-slide {
	 	img {
			border: 1px solid $minday-gray-light;
		}
       .slick-arrow {

       }
	 }

	 .recognition-title {
	 	color: $minday-gray;
	 	font-size: 20px;
	 	margin-bottom: 18px;

	 	@include screen-md() {
			font-size: 33px;
	 	}

	 }

	 .slick-list {
	 	margin-left: -5px;
	 }

	}

	#awards {
		margin-top: 36px;

		@include screen-md() {
			margin-top: 72px;
		}

	}

}

.recognition-section {
  margin-top: 150px;
  .btn-more-container {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    .glyphicon {
    	padding-left: 7px;
    }
  }
}

.post-type-archive-minday_recognition {
  .main {
    padding-right: 18px;
    padding-left: 18px;

    @include screen-sm() {
      padding-right: 27px;
      padding-left: 27px;
    }
  }
  .project-tiles-container {
    margin-top: 54px;
  }
  .button-container {
    text-align: right;
  }
}


/**
Recognition Grid
 */
.container-recognition-grid {
  position: relative;
  margin-top: 54px;
  margin-left: -5px;
  margin-right: -5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
  &.result {
    margin-top: 0;
  }
  .cell {
    background-color: #efefef;
    padding: 0;
    position: relative;
    opacity: 1;
    -ms-flex: 1 1 100%;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    margin: 5px;

    @include screen-sm() {
      -ms-flex: 1 1 calc(50% - 10px);
      -webkit-flex: 1 1 calc(50% - 10px);
      flex: 1 1 calc(50% - 10px);
      width: calc(50% - 10px);
      max-width: calc(50% - 10px);
      margin: 5px;
    }
    @include screen-lg() {
      -ms-flex: 1 1 calc(33% - 10px);
      -webkit-flex: 1 1 calc(33% - 10px);
      flex: 1 1 calc(33% - 10px);
      max-width: calc(33% - 10px);
      width: calc(33% - 10px);
      margin: 5px;
      padding: 0;
    }
    @media(min-width: 1600px) {
      -ms-flex: 1 1 calc(25% - 10px);
      -webkit-flex: 1 1 calc(25% - 10px);
      flex: 1 1 calc(25% - 10px);
      max-width: calc(25% - 10px);
      width: calc(25% - 10px);
      margin: 5px;
      padding: 0;

    }
    &:hover .overlay-image-container {
      opacity: 1;
    }
  }

  .overlay-image-container {
    transition: all 0.2s ease-in-out;
    opacity: 0;
    position: relative;
    z-index: 3;
    background-color: #efefef;
  }

  .content-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    .content {
      margin: 15px 20px;
      .top-section {
        color: #ccc;
        font-size: 18px;
      }
      .bottom-section {

        span.link-text {
          text-decoration: underline;
          font-size: 20px;
          color: #7a7976;
        }
      }
    }
  }
}
